import topnavScroll from './landingTopnavScroll';
import videoControl from './landingvideoControl';

/**
 * This function initializes all modules that belong to landing pages
 *
 * @function
 */
function initLanding() {
    topnavScroll();
    videoControl();
}

initLanding();
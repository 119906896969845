export default function topnavScroll() {
    const header = document.querySelector("[data-header]");
    const topnav = document.querySelector("[data-topnav]");
    const sticky = 1;

    window.onscroll = () => {
        if (window.pageYOffset >= sticky) {
            header.dataset.scroll = "true";
            topnav.dataset.scroll = "true";
        } else {
            header.dataset.scroll = "";
            topnav.dataset.scroll = "";
        }
    };
}